import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { hydrate, render } from 'react-dom';
import './index.css';
import App from './App';

const root = document.getElementById('root');
if (root.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    root,
  );
} else {
  render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    root,
  );
}
// root.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
// );
