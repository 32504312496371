import React from 'react';
import { Routes, Route, Router } from 'react-router-dom';

import Main from './pages/Main';
import About from './pages/About';
import ItOutstaffing from './pages/Services/ItOutstaffing';
import ItOutsourcing from './pages/Services/ItOutsourcing';
import CreationAndDevelopmentOfSites from './pages/Services/CreationAndDevelopmentOfSites';
import Audit from './pages/Services/Audit';
import SolutionForBusiness from './pages/Services/SolutionForBusiness';
import TechnicalVisualization from './pages/Services/TechnicalVisualization';
import NotFound from './pages/NotFound';

import './scss/app.scss';
import VRDevelopment from './pages/Services/VRDevelopment';
import Politika from './pages/Politika';

function App() {
  return (
    <div className="wrapper">
      <Routes>
        <Route path="/" element={<Main />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/itoutstaffing" element={<ItOutstaffing />}></Route>
        <Route path="/itoutsourcing" element={<ItOutsourcing />}></Route>
        <Route path="/creationanddevelopment" element={<CreationAndDevelopmentOfSites />}></Route>
        <Route path="/audit" element={<Audit />}></Route>
        <Route path="/solutionforbusiness" element={<SolutionForBusiness />}></Route>
        <Route path="/technicalvisualization" element={<TechnicalVisualization />}></Route>
        <Route path="/vrdevelopment" element={<VRDevelopment />}></Route>
        <Route path="/politika" element={<Politika />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>

      {/* <About /> */}
    </div>
  );
}

export default App;
