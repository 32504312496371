import React from 'react';
import { Link } from 'react-router-dom';

import itOutstaffing from '../assets/img/mainService/itOutstaffing.png';
import itOutsourcing from '../assets/img/mainService/itOutsourcing.png';
import creationAndDevelopmentOfSites from '../assets/img/mainService/creationAndDevelopmentOfSites.png';
import audit from '../assets/img/mainService/audit.png';
import solutionForBusiness from '../assets/img/mainService/solutionForBusiness.png';
import technicalVisualization from '../assets/img/mainService/technicalVisualization.png';
import VRDevelopment from '../assets/img/mainService/VRDevelopment.png';
import customDevelopment from '../assets/img/mainService/customDevelopment.png';
import onlineOffice from '../assets/img/mainService/onlineOffice.png';

const Services = () => {
  return (
    <div className="services" id="services">
      <h2>Услуги</h2>
      <div className="services__container">
        <div className="block">
          <Link to="/itoutstaffing">
            <img src={itOutstaffing} alt="Картинка для услуги" />
            {/* <div className="block-text">
              <span>IT-АУТСТАФФИНГ</span>
            </div> */}
          </Link>
        </div>
        <div className="block">
          <Link to="/itoutsourcing">
            <img src={itOutsourcing} alt="Картинка для услуги" />
            {/* <div className="block-text">
              <span>IT-АУТСТАФФИНГ</span>
            </div> */}
          </Link>
        </div>
        <div className="block">
          <Link to="/creationanddevelopment">
            <img src={creationAndDevelopmentOfSites} alt="Картинка для услуги" />
            {/* <div className="block-text">
              <span>СОЗДАНИЕ И РАЗРАБОТКА САЙТОВ</span>
            </div> */}
          </Link>
        </div>
        <div className="block">
          <Link to="/audit">
            <img src={audit} alt="Картинка для услуги" />
            {/* <div className="block-text">
              <span>АУДИТ</span>
            </div> */}
          </Link>
        </div>
        <div className="block">
          <Link to="/solutionforbusiness">
            <img src={solutionForBusiness} alt="Картинка для услуги" />
            {/* <div className="block-text">
              <span>РЕШЕНИЕ ДЛЯ БИЗНЕСА</span>
            </div> */}
          </Link>
        </div>
        <div className="block">
          <Link to="/technicalvisualization">
            <img src={technicalVisualization} alt="Картинка для услуги" />
            {/* <div className="block-text">
              <span>ТЕХНИЧЕСКАЯ ВИЗУАЛИЗАЦИЯ</span>
            </div> */}
          </Link>
        </div>
        {/* <div className="block">
          <Link to="/vrdevelopment">
            <img src={VRDevelopment} alt="Картинка для услуги" />
           
          </Link>
        </div> */}
        <div className="block">
          <Link to="/vrdevelopment">
            <img src={onlineOffice} alt="Картинка для услуги" />
          </Link>
        </div>
        {/* <div className="block">
          <Link to="">
            <img src={customDevelopment} alt="Картинка для услуги" />
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Services;
