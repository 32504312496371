import React from 'react';
import { Link } from 'react-router-dom';

import Form from '../components/Form';

import logo from '../assets/img/svg/newLogo.svg';

const Header = () => {
  const [modalActive, setModalActive] = React.useState(false);
  return (
    <>
      <div className="header">
        <div className="header__container">
          <div className="header__nav">
            <Link to="/">Услуги</Link>
            <Link to="/about">О компании</Link>
          </div>
          <Link to="/">
            <img src={logo} alt="Логотип сайта Виртэк" />
          </Link>
          <a href="#form" className="header__button" onClick={() => setModalActive(true)}>
            Связаться с нами
          </a>
        </div>
      </div>
      <Form active={modalActive} setActive={setModalActive} />
    </>
  );
};

export default Header;
