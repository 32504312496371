import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

const Maps = () => {
  const defaultState = {
    center: [54.733026, 55.951172],
    zoom: 17,
  };
  return (
    <YMaps>
      <Map width={2000} defaultState={defaultState}>
        <Placemark geometry={[54.733026, 55.951172]} />
      </Map>
    </YMaps>
  );
};

export default Maps;
