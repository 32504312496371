import React from 'react';

import Header from '../../components/Header';
import Form from '../../components/Form';
import Footer from '../../components/Footer';

import technicalVisualization from '../../assets/img/services/technicalVisualization.png';

const TechnicalVisualization = () => {
  window.scroll(0, 0);
  return (
    <>
      <Header />
      <div className="service">
        <div className="service__intro">
          <img src={technicalVisualization} alt="Услуга" />
          <h2>
            <span>ТЕХНИЧЕСКАЯ ВИЗУАЛИЗАЦИЯ</span>
            <span>
              Визуализация позволяет создавать любые модели чего-либо: от сложных технических
              установок и машинных деталей до интерьеров и площадок. Главная цель - рассказать
              предполагаемому клиенту или заказчику о продукте/процессе так, чтоб он захотел его
              купить.
            </span>
          </h2>
        </div>
        <span>Помимо этого, техническая визуализация позволяет:</span>
        <span>
          <p>• Реализовать самые разные проекты и предоставить простую и понятную визуализацию</p>
          <p>• Сформировать у клиента полное представление о продукте</p>
          <p>• Представить, как будет выглядеть товар (объект) при выпуске на рынок</p>
        </span>
        <span>
          В ООО НПЦ “ВирТЭК” мы предлагаем клиентам разработку 3D-моделей с нуля и доработку уже
          готовых работ. Вам не нужно переживать о покупке специального ПО для разработки и поиска
          специалиста - все это уже есть в нашей компании.
        </span>
        <span>
          Основным инструментом для реализации данной услуги в нашей компании выступает Компас-3D -
          программный комплекс компании Аскон, который позволяет реализовать огромное количество
          вариантов инженерных решений и беспрепятственно внести изменения в уже существующие
          разработки.
        </span>
        <span>
          Вы также можете согласовать разработку в других программных средствах - для этого просто
          свяжитесь с нами.
        </span>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default TechnicalVisualization;
