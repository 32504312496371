import React from 'react';

import Header from '../../components/Header';

import itOutstaffing from '../../assets/img/services/itOutstaffing.png';
import Form from '../../components/Form';
import Footer from '../../components/Footer';

const Service = () => {
  window.scroll(0, 0);
  return (
    <>
      <Header />
      <div className="service">
        <div className="service__intro">
          <img src={itOutstaffing} alt="Услуга" />
          <h2>
            <span>ИТ-АУТСТАФФИНГ </span>
            <span>
              Процесс подбора и найма квалифицированных специалистов может растянуться от 2 до 6
              месяцев и более. Так же мы аккредитованная ИТ компания и пользуемся льготами от
              государства - в том числе более низкими ставками по налогам.{' '}
              <b>
                Но что делать, если хороший сотрудник нужен срочно, а расширить штат - нет
                возможности?
              </b>
            </span>
          </h2>
        </div>
        <span>
          На помощь придет <b>ИТ-аутстаффинг</b> - быстрый способ закрыть недостающие компетенции
          для выполнения проектов в срок. Механизм прост: вы “арендуете” наших специалистов на
          определенное время, самостоятельно определяете их задачи.
        </span>
        <span>
          При этом вы экономите время и деньги на поиск специалиста. Как только потребность в таком
          специалисте пропадет, вы можете отказаться от услуг без финансовых потерь и бумажной
          волокиты.
        </span>
        <span>
          <b>Когда необходим аутстаффинг?</b>
        </span>
        <span>Найм внештатного сотрудника нужен, если: </span>
        <span>
          <p>
            • Вам нужны компетентные специалисты, но нет возможности создать дополнительные рабочие
            места
          </p>
          <p>• Вы создаете проект и не хотите загружать штатных специалистов этой задачей</p>
          <p>• В вашей компании нет специалистов определенной квалификации</p>
          <p>• Ваша компания ведет деятельность в другой стране</p>
        </span>
        <span>
          Аутстаффинг упрощает работу команды над крупными проектами и снижает затраты на
          дополнительные рабочие места.
        </span>
        <span>
          <b>Как происходит ИТ-аутстаффинг в нашей компании?</b>
        </span>
        <span>
          Опыт работы с разными предприятиями позволяет нам сократить время подбора индивидуальных
          специалистов и команд к минимуму:
        </span>
        <span>
          <p>1. Вы предоставляете нам информацию о проекте: направление, задачи, сроки и бюджет.</p>
          <p>
            2. Наш представитель связывается с вами для уточнения деталей и квалификации
            специалистов.
          </p>
          <p>
            3. Мы предоставляем вам резюме кандидатов, а вы их согласовываете на основе ваших
            предпочтений.
          </p>
        </span>
        <span>
          Простые 3 шага - и ваш проект укомплектован квалифицированными ИТ-специалистами, которые
          готовы сразу приступить к работе.
          <b>Экономия от использования внештатных специалистов составит примерно 30-40%.</b>
        </span>
        <span>Для расчета стоимости ИТ-аутсорсинга для вашей компании свяжитесь с нами.</span>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default Service;
