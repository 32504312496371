import React from 'react';

import Header from '../../components/Header';
import Form from '../../components/Form';
import Footer from '../../components/Footer';

import solutionForBusiness from '../../assets/img/services/solutionForBusiness.png';

const SolutionForBusiness = () => {
  window.scroll(0, 0);
  return (
    <>
      <Header />
      <div className="service">
        <div className="service__intro">
          <img src={solutionForBusiness} alt="Услуга" />
          <h2>
            <span>РЕШЕНИЕ ДЛЯ БИЗНЕСА</span>
            <span>
              Наша компания осуществляет комплексное системное администрирование, которое включает в
              себя: <br />
              • Подбор конфигурации сервера под ваши нужды; <br />
              • Администрирование, настройка серверов, сетевых хранилищ, баз данных; <br />
              • Администрирование серверов 1С и ERP; <br />• Подбор, настройка и администрирование
              почтового сервера.
            </span>
          </h2>
        </div>
        <span>
          • Создание, настройка корпоративной почты;
          <br />
          • Виртуализация серверов, мониторинг серверных ОС, обеспечение бесперебойной работы и
          информационной безопасности;
          <br />
          • Оптимизация, обновление, настройка операционных систем (Windows, Linux);
          <br />
          • Установка, настройка программного обеспечения;
          <br />
          • Установка, настройка систем сдачи отчетности через Интернет, CRM-систем, доступов на
          электронные площадки;
          <br />
          • Настройка офисного периферийного оборудования (принтеры, плоттеры, МФУ, сканеры);
          <br />
          • Настройка сетевого оборудования (маршрутизаторы, управляемые коммутаторы, точки доступа
          Wi-Fi);
          <br />
          • Настройка VPN туннелей, IPSec;
          <br />
          • Анализ состояния компьютерного парка, ускорение работы компьютеров и серверов;
          <br />
          • Установка, настройка системы резервного копирования данных;
          <br />
          • Проектирования и развертывания Active Directory, Cluster, Replication;
          <br />
          • Развертывания Directum;
          <br />
          • Развертывание и администрирование Asterisk IP;
          <br />
          • Администрирование Primavera;
          <br />
          • Устранения неполадок;
          <br />
          • Восстановление доступа к данным;
          <br />
          • Организация облачного хранилища;
          <br />• Консультации и поддержка пользователей, работающих как в офисе, так и удаленно.
        </span>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default SolutionForBusiness;
