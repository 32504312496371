import React from 'react';

const OperatingSystem = () => {
  return (
    <div className="operating-system">
      <div className="operating-system__container">
        <p>Почему Virtek?</p>
        <div className="block">
          <p>Компетенция и опыт</p>
          <p>Сопровождение на каждом этапе</p>
          <p>Экономия времени</p>
          <p>Гибкость</p>
          <p>Экономия ресурсов</p>
          <p>Безопасность</p>
        </div>
        {/* <div className="block">
          <p>Гибкость</p>
          <p>Экономия ресурсов</p>
          <p>Безопасность</p>
        </div> */}
      </div>
    </div>
  );
};

export default OperatingSystem;
