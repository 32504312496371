import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

import informationSecurity from '../assets/img/slider/informationSecurity.jpg';
import development from '../assets/img/slider/development.jpg';
import avtomatedControlSystem from '../assets/img/slider/avtomatedControlSystem.jpg';
import VR from '../assets/img/slider/VR.jpg';

import firstSlide from '../assets/img/slider/firstSlide.png';
import secondSlide from '../assets/img/slider/secondSlide.png';
import thirdSlide from '../assets/img/slider/thirdSlide.png';
import fourthSlide from '../assets/img/slider/fourthSlide.png';
import fifthSlide from '../assets/img/slider/fifthSlide.png';
import sixthSlide from '../assets/img/slider/sixthSlide.png';

const Slider = () => {
  return (
    // <Carousel>
    //   <Carousel.Item interval={500}>
    //     <img className="d-block w-100 slider-img" src={informationSecurity} alt="First slide" />
    //   </Carousel.Item>
    //   <Carousel.Item interval={500}>
    //     <img className="d-block w-100 slider-img" src={development} alt="Second slide" />
    //   </Carousel.Item>
    //   <Carousel.Item interval={500}>
    //     <img className="d-block w-100 slider-img" src={avtomatedControlSystem} alt="Third slide" />
    //   </Carousel.Item>
    //   <Carousel.Item interval={500}>
    //     <img className="d-block w-100 slider-img" src={VR} alt="Third slide" />
    //   </Carousel.Item>
    // </Carousel>

    <Carousel>
      <Carousel.Item interval={500}>
        <img className="d-block w-100 slider-img" src={firstSlide} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img className="d-block w-100 slider-img" src={secondSlide} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img className="d-block w-100 slider-img" src={thirdSlide} alt="Third slide" />
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img className="d-block w-100 slider-img" src={fourthSlide} alt="Third slide" />
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img className="d-block w-100 slider-img" src={fifthSlide} alt="Third slide" />
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img className="d-block w-100 slider-img" src={sixthSlide} alt="Third slide" />
      </Carousel.Item>
    </Carousel>
  );
};

export default Slider;
