import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import politika from '../assets/doc/politika.pdf';

import Intro from '../components/Intro';
import Services from '../components/Services';
import Slider from '../components/Slider';
import Footer from '../components/Footer';
import OperatingSystem from '../components/OperatingSystem';
import Contact from '../components/Contact';
import Maps from '../components/Map';
// import Contact from '../components/Contact';

const Main = () => {
  const [activeCookie, setActiveCookie] = React.useState(true);
  console.log(activeCookie);
  return (
    <>
      <Intro />
      <Services />
      <OperatingSystem />
      <Slider />
      <Contact />
      <Maps />
      <Footer />
      {activeCookie && (
        <div className="modal-cookie">
          <p className="modal-text">
            Мы используем{' '}
            <a target="_blank" className="modal-link" href={politika}>
              cookie-файлы
            </a>
          </p>
          <button onClick={() => setActiveCookie(false)} className="modal-button">
            Понятно
          </button>
        </div>
      )}
    </>
  );
};

export default Main;
