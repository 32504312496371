import React from 'react';

import Header from '../../components/Header';
import Form from '../../components/Form';
import Footer from '../../components/Footer';

import itOutsourcing from '../../assets/img/services/itOutsourcing.png';

const ItOutsourcing = () => {
  window.scroll(0, 0);
  return (
    <>
      <Header />
      <div className="service">
        <div className="service__intro">
          <img src={itOutsourcing} alt="Услуга" />
          <h2>
            <span>ИТ-АУТСОРСИНГ</span>
            <span>
              Трудно представить современную компанию без компьютеров с узконаправленным ПО,
              серверов, элементов сети, оргтехники, телефонии, облачных хранилищ и другого
              немаловажного для бизнеса оборудования.
            </span>
          </h2>
        </div>
        <span>
          Для их комплексного технического обслуживания требуются значительные затраты: финансовые и
          трудовые. Поиск квалифицированных специалистов и покупка оборудования могут оттянуть
          запуск компании или проекта на неопределенный период.
        </span>
        <span>
          ИТ-аутсорсинг позволяет избежать этих проблем и оптимизировать ресурсы предприятия, кратно
          увеличить прибыль за счет передачи непрофильных функций внешним исполнителям. Вам не нужно
          будет нанимать штатных специалистов, обустраивать их рабочие места, оформлять трудовой
          договор, выдавать больничные листы и платить аренду за дополнительную площадь.
        </span>
        <span>
          При этом вы получите гарантированно качественный результат и работу в срок – без горящих
          дедлайнов и отложенных задач.
        </span>
        <span>
          <b>Как понять, что вашей компании нужен аутсорсинг в сфере ИТ?</b>
        </span>
        <span>Привлечение внешних исполнителей необходимо, если бизнесу нужно:</span>
        <span>
          <p>• Сократить общие расходы на обслуживание аппаратуры</p>
          <p>• Уделить больше внимания основному направлению</p>
          <p>• Повысить качество работы и конечного продукта</p>
          <p>• Выполнять задачи в незнакомых ранее сферах</p>
          <p>• Решить проблемы с производительностью</p>
        </span>
        <span>
          ИТ-аутсорсинг позволит вам сосредоточиться на том, что действительно важно для компании –
          на стратегических задачах.
        </span>
        <span>
          <b>Как происходит ИТ-аутсорсинг в нашей компании?</b>
        </span>
        <span>
          Наши специалисты снимают всю лишнюю техническую нагрузку с клиентов: вам не нужно
          переживать о стоимости диагностики оборудования в случае неисправности или о
          непредвиденных сбоях.
        </span>
        <span>Мы предлагаем:</span>
        <span>
          <p>
            • Комплексное техническое обслуживание оборудования (серверов, компьютеров, ноутбуков )
          </p>
          <p>• Дистанционную поддержку пользователей</p>
          <p>• Мониторинг ИТ-инфраструктуры для предотвращения сбоев</p>
          <p>• Организация непрерывной работы ИТ-сервисов</p>
          <p>• Обеспечение сохранности данных компании</p>
        </span>
        <span>
          Качественный ИТ-аутсорсинг обеспечит вам снижение рисков при сбоях ИТ-инфраструктуры и
          высокий уровень сервиса. Контроль за техническим обслуживанием позволит предоставлять
          качественные услуги.
        </span>
        <span>Для расчета стоимости ИТ-аутсорсинга для вашей компании свяжитесь с нами.</span>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default ItOutsourcing;
