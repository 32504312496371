import React from 'react';

import soglasie from '../assets/doc/soglasie.pdf';
import politika from '../assets/doc/politika.pdf';

const Form = ({ active, setActive }) => {
  console.log(active);
  return (
    <div className={active ? 'active__form' : 'modal'} id="form" onClick={() => setActive(false)}>
      <div className="form__container" onClick={(el) => el.stopPropagation()}>
        <h2>Связаться с нами</h2>
        <form action="../php/mail.php" method="POST">
          <input id="name" type="text" placeholder="Ваше имя*" />
          <input id="company" type="text" placeholder="Организация" />
          <input id="email" type="text" placeholder="E-mail*" />
          <input id="phone" type="text" placeholder="Телефон*" />
          <input id="text" type="text" placeholder="Комментарий*" />
          <span>
            Нажимая на кнопку «Отправить», вы даете{' '}
            <a target="_blank" href={soglasie}>
              согласие
            </a>{' '}
            на обработку персональных данных и соглашаетесь c{' '}
            <a target="_blank" href={politika}>
              политикой
            </a>{' '}
            в отношении обработки персональных данных
          </span>
          <input id="bnt" className="button" type="submit" value="Отправить" />
        </form>
        <button className="form__close" onClick={() => setActive(false)}></button>
      </div>
    </div>
  );
};

export default Form;
