import React from 'react';
import { Link } from 'react-router-dom';

import Form from '../components/Form';

import logo from '../assets/img/svg/newLogo.svg';

const Intro = () => {
  const [modalActive, setModalActive] = React.useState(false);
  return (
    <>
      <div className="intro">
        <div className="intro__header">
          <div className="intro__header-container">
            <div className="intro__header-nav">
              <a href="#services">Услуги</a>
              <Link to="/about">О компании</Link>
            </div>
            <Link to="/">
              <img src={logo} alt="Логотип сайта Виртэк" />
            </Link>
            <a href="#form" className="intro__header-button" onClick={() => setModalActive(true)}>
              Связаться с нами
            </a>
          </div>
        </div>
        <div className="intro__main">
          <div className="intro__main-container">
            <h1>
              Virtek
              <br /> Innovation-Transformation
            </h1>
            <span>Качественные услуги для вашего бизнеса</span>
            <Link to="/about">
              <div className="button">
                <a href="#">О компании</a>
              </div>
            </Link>
          </div>
        </div>
        <div className="intro__benefits">
          <div className="intro__benefits-container">
            <div className="block">
              <h3>Разработка программного обеспечения</h3>
              {/* <span>Разрабатываем исключительные цифровые продукты и услуги.</span> */}
            </div>
            <div className="block">
              <h3>Автоматизация бизнес процессов</h3>
              {/* <span>Внедряем и сопровождаем информационные системы без превышения бюджета.</span> */}
            </div>
            <div className="block">
              <h3>Модернизация компьютеров</h3>
              {/* <span>Способствуем достижению целей компании.</span> */}
            </div>
            <div className="block">
              <h3>Бизнес под ключ</h3>
            </div>
          </div>
        </div>
      </div>
      <Form active={modalActive} setActive={setModalActive} />
    </>
  );
};

export default Intro;
