import React from 'react';

const Company = (props) => {
  return (
    <div className="company__block partner">
      <a href={props.link} target="_blank">
        <img src={props.img} alt="Логотип" />
      </a>
    </div>
  );
};

export default Company;
