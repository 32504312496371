import React from 'react';

import Header from '../../components/Header';
import Form from '../../components/Form';
import Footer from '../../components/Footer';

import vrDevelopment from '../../assets/img/services/VDI.png';

const VRDevelopment = () => {
  window.scroll(0, 0);
  return (
    <>
      <Header />
      <div className="service">
        <div className="service__intro">
          <img src={vrDevelopment} alt="Услуга" />
          <h2>
            <span>VDI - Удаленные рабочие места</span>
            <span>
              Буквально 10 лет назад возможность полноценно работать из дома казалась
              неосуществимой. Но после пандемии 2020 года, “удаленка” - стала одним из самых
              распространенных способов организации рабочего процесса.
            </span>
          </h2>
        </div>
        <span>
          Удаленные рабочие места позволяют обеспечить дистанционную работу сотрудников без
          качественных потерь и особого оборудования. Сотрудникам не нужно скачивать корпоративные
          приложения, все они могут хранится в облаке, к которому вы сами определяете доступ.
        </span>
        <span>
          <b>Когда необходимы удаленные рабочие места?</b>
        </span>
        <span>
          <p>• Потребность перевести штат сотрудников в дистанционный формат</p>
          <p>• Краткосрочные проекты, которые требуют дополнительных сотрудников</p>
          <p>• Сокращение расходов на временные рабочие места</p>
          <p>• Стартапы с командами в разных городах, странах</p>
        </span>
        <span>
          Мы создаем индивидуальные решения для каждого клиента в зависимости от масштаба
          организации и выполняемых задач.
        </span>
        <span>
          <b>Преимущества удаленных рабочих мест:</b>
        </span>
        <span>
          <p>- Сокращение издержек</p>
          <p>
            Не нужно приобретать дополнительные ноутбуки, ПО, лицензии и другие элементы рабочего
            пространства.
          </p>
          <p>- Надежность данных</p>
          <p>
            Сотрудники компании имеют доступ только к ограниченным функциям и данным. Возможность
            нарушить их целостность практически отсутствует.{' '}
          </p>
          <p>- Простота использования для сотрудников</p>
          <p>
            Мы настраиваем облачные рабочие места на всех уровнях и выдаем краткие инструкции по
            запуску для вашего персонала. Вам не нужно будет заново обучать персонал и тратить время
            на налаживание всех процессов.
          </p>
          <p>- Возможность масштабирования</p>
          <p>
            Налаженная система удаленных рабочих мест может легко масштабироваться и подстраиваться
            под новые задачи компании.
          </p>
        </span>
        <span>
          <b>Как происходит создание удаленных рабочих мест в нашей компании?</b>
        </span>
        <span>Для создания удаленных рабочих мест в вашей компании свяжитесь с нами.</span>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default VRDevelopment;
