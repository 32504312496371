import React from 'react';

import Header from '../../components/Header';
import Form from '../../components/Form';
import Footer from '../../components/Footer';

import audit from '../../assets/img/services/audit.png';

const Audit = () => {
  window.scroll(0, 0);
  return (
    <>
      <Header />
      <div className="service">
        <div className="service__intro">
          <img src={audit} alt="Услуга" />
          <h2>
            <span>АУДИТ БЕЗОПАСНОСТИ СУЩЕСТВУЮЩИХ СИСТЕМ</span>
            <span>
              Информация сейчас играет особую роль в бизнесе - без нее невозможно представить ни
              одну компанию. Ее потеря, утечка данных или целенаправленные атаки злоумышленников
              могут сильно навредить работе предприятия, его репутации и принести крупные финансовые
              потери. Мы рекомендуем регулярно проводить аудит безопасности существующей системы.
            </span>
          </h2>
        </div>
        <span>
          Тщательно тестируем инструменты каждого бизнес-процесса, чтобы обеспечить полную защиту
          вашего предприятия и предотвратить несанкционированный доступ.
        </span>
        <span>
          <b>Какие услуги предоставляем?</b>
        </span>
        <span>
          <p>• Инструментальный аудит</p>
          <p>
            Подразумевает использование программных (и реже аппаратных) комплексов сканирования на
            предмет выявления уязвимостей. Типичными примерами таких средств могут быть Positive
            xSpider, MaxPatrol, Nessus, Aqunetics и другие. Такой инструмент дает общую картину о
            защищенности сети компании, а в многостраничном отчете сканера безопасности может быть
            много ложных срабатываний.
          </p>
          <p>• Ручной аудит </p>
          <p>
            Тестирование проходит по методу "белого", "серого" или "черного ящика" - определяется
            количеством информации, которое специалист знает до начала тестирования. В рамках теста
            создаются эксплойты, чтобы анализировать уязвимости с нуля. Такой аудит заканчивается
            детальным отчетом об успешных результатах взлома и путях потенциального хакера, которым
            тот может воспользоваться для проникновения.
          </p>
          <p>• Автоматический аудит</p>
          <p>
            Применяем подход на основе автоматизации, но целью является выявление конкретных
            возможных векторов проникновения нарушителей в вашу сеть. Объединяем простой вариант
            сканера уязвимостей с тестом на проникновение. В этом случае в инфраструктуре
            устанавливается специализированное решение, созданное нашими сотрудниками.
          </p>
        </span>
        <span>
          Проведение аудита безопасности не только убережет вашу компанию от материальных потерь, но
          и сохранит конфиденциальные данные, уникальные разработки от злоумышленников.
        </span>
        <span>Для расчета стоимости аудита безопасности системы свяжитесь с нами.</span>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default Audit;
