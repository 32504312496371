import React from 'react';

import Header from '../../components/Header';
import Form from '../../components/Form';
import Footer from '../../components/Footer';

import creationAndDevelopmentOfSites from '../../assets/img/services/creationAndDevelopmentOfSites.png';

const CreationAndDevelopmentOfSites = () => {
  window.scroll(0, 0);
  return (
    <>
      <Header />
      <div className="service">
        <div className="service__intro">
          <img src={creationAndDevelopmentOfSites} alt="Услуга" />
          <h2>
            <span>СОЗДАНИЕ И РАЗРАБОТКА САЙТОВ</span>
            <span>
              Сегодня нельзя представить работу компаний и предприятий без сайтов и приложений. И
              это не просто показатель масштаба компании. Это инструмент, который позволяет:
            </span>
            <span>
              <p>• Оптимизировать бизнес-процессы</p>
              <p>• Распространять информацию</p>
              <p>• Развивать сферы влияния бизнеса</p>
              <p>• Продавать товары и услуги через интернет</p>
            </span>
          </h2>
        </div>
        <span>
          Больше 10 лет разрабатываем web-приложения, которые бесперебойно функционируют,
          оптимизируются к требованиям поисковых систем и обеспечивают выполнение задач бизнеса.
        </span>
        <span>
          <b>Какие услуги предоставляем?</b>
        </span>
        <span>
          <p>- Создание лендинга, сайта-визитки, интернет-магазина</p>
          <p>
            Для каждого клиента разрабатываем индивидуальную структуру сайта под основные задачи
            бизнеса: информирование, продажа и т.д.
          </p>
          <p>- Разработка корпоративных порталов</p>
          <p>
            С нуля реализуем веб-интерфейсы для внутренних задач компании: обмен информацией между
            сотрудниками, организация совместной работы и т.д.
          </p>
          <p>- Адаптация сайта под разные девайсы</p>
          <p>
            Делаем ваше web-приложения “подходящим” для десктопных мониторов, планшетов и
            смартфонов. Вам не нужно будет беспокоиться, что сайт неправильно отображается на разных
            девайсах.
          </p>
          <p>- Верстка и дизайн готового сайта</p>
          <p>
            Создаем код для визуальной части, которая не просто цепляет взгляд пользователя, но и
            соответствует требованиям поисковых систем. Дополняем все современным дизайном, который
            передает ценности вашей компании.
          </p>
          <p>- Программирование</p>
          <p>
            Дорабатываем структуру существующего сайта. Наш специалист может добавить недостающий
            функционал: фильтры, калькулятор, формы обратной связи, виджеты и другие элементы. В
            работе используются передовые инструменты и новейшие подходы в программировании.
          </p>
          <p>- Хостинг сайтов</p>
          <p>
            Регистрируем оптимальный домен и хостинг. Сверстанный и практически готовый к работе
            сайт появится в интернете и будет доступен вашей целевой аудитории.
          </p>
        </span>
        {/* <span>
          <b>Наши кейсы:</b>
        </span> */}
        <span>Для расчета стоимости создания и разработки web-приложения свяжитесь с нами.</span>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default CreationAndDevelopmentOfSites;
