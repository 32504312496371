import React from 'react';
import Header from '../components/Header';

const Politika = () => {
  return (
    <>
      <Header />
      <div className="politika">
        <h2>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
        <h2>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ НАУЧНЫЙ ПРОЕКТНЫЙ ЦЕНТР "ВИРТЭК"</h2>
        <h2>(ООО НПЦ "ВИРТЭК"; ИНН 0274922889; ОГРН 1160280137820)</h2>
        <h2>Юридический адрес 450008, г. Уфа, ул. Ленина, д. 70, ЭТАЖ 5, ОФИС №75</h2>
        <p>
          Настоящая Политика конфиденциальности персональных данных (далее — «Политика
          конфиденциальности») составлена в соответствии с требованиями Федерального закона «О
          персональных данных» № 152-ФЗ от 27 июля 2006 г., а также иными нормативными правовыми
          актами Российской Федерации в области защиты и обработки персональных данных и действует в
          отношении всей информации, которую ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ НАУЧНЫЙ
          ПРОЕКТНЫЙ ЦЕНТР "ВИРТЭК" (ООО НПЦ "ВИРТЭК") (далее — «Организация») на доменных именах
          virtek.pro может получить о Пользователе во время использования сайта.
        </p>
        <h2>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</h2>
        <p>1.1. В настоящей Политике конфиденциальности используются следующие термины:</p>
        <p>
          1.1.1. «Администрация сайта» — уполномоченные на управление Сайтом сотрудники, действующие
          от имени Организации, которые организуют и (или) осуществляют обработку персональных
          данных, а также определяет цели обработки персональных данных, состав персональных данных,
          подлежащих обработке, действия (операции), совершаемые с персональными данными.
        </p>
        <p>
          1.1.2. «Персональные данные» — любая информация, относящаяся к прямо или косвенно
          определенному или определяемому физическому лицу (субъекту персональных данных).
        </p>
        <p>
          1.1.3. «Обработка персональных данных» — любое действие (операция) или совокупность
          действий (операций), совершаемых с использованием средств автоматизации или без
          использования таких средств с персональными данными, включая сбор, запись, систематизацию,
          накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
          передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
          уничтожение персональных данных.
        </p>
        <p>
          1.1.4. «Конфиденциальность персональных данных» — обязательное для соблюдения Организацией
          или иным получившим доступ к персональным данным лицом требование не допускать их
          распространения без согласия субъекта персональных данных или наличия иного законного
          основания.
        </p>
        <p>
          1.1.5. «Пользователь сайта» (далее — «Пользователь») — лицо, имеющее доступ к Сайту,
          посредством сети Интернет и использующее Сайт.
        </p>
        <h2>2. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
        <p>
          2.1. Использование Пользователем сайта означает согласие с Политикой конфиденциальности
          Организации и условиями обработки персональных данных Пользователя.
        </p>
        <p>
          2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен
          прекратить использование Сайта.
        </p>
        <p>
          2.3. Настоящая Политика конфиденциальности применяется только к сайтам ООО НПЦ "ВИРТЭК" с
          доменными именами virtek.pro. Организация не контролирует и не несет ответственность за
          сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.
        </p>
        <p>
          2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых
          Пользователем сайта.
        </p>
        <h2>3. ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
        <p>
          3.1. Настоящая Политика конфиденциальности устанавливает обязательства Организации по
          неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые
          Пользователь предоставляет по запросу Организации при регистрации на Сайте или при
          оформлении заявки на обратный звонок, заявки на консультацию на Сайте.
        </p>
        <p>
          3.2. К основным категориям субъектов персональных данных, чьи данные обрабатываются в
          Организации, относятся пользователи сайта и информационных ресурсов Организации;
        </p>
        <p>
          3.3. Пользователь дает согласие Организации на обработку и хранение персональных данных, в
          том числе, полученных путем заполнения формы заявки на Сайте и может включать в себя
          следующую информацию:
        </p>
        <p>• имя;</p>
        <p>• номер телефона субъекта персональных данных;</p>
        <p>• электронный адрес субъекта персональных данных;</p>
        <p>• сведения о месте работы.</p>
        <p>
          3.4. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию при
          наступлении следующих условий:
        </p>
        <p>
          3.4.1. Достижение целей обработки персональных данных или максимальных сроков хранения —
          подлежит уничтожению либо обезличиванию в течение 30 дней;
        </p>
        <p>
          3.4.2. Утрата необходимости в достижении целей обработки персональных данных — в течение
          30 дней;
        </p>
        <p>
          3.4.3. Предоставление субъектом персональных данных или его законным представителем
          подтверждения того, что персональные данные являются незаконно полученными или не являются
          необходимыми для заявленной цели обработки — в течение 7 дней;
        </p>
        <p>
          3.4.4. Невозможность обеспечения правомерности обработки персональных данных — в течение
          10 дней;
        </p>
        <p>
          3.4.5. Отзыв субъектом персональных данных согласия на обработку персональных данных, если
          сохранение персональных данных более не требуется для целей обработки персональных данных
          — в течение 30 дней;
        </p>
        <p>
          3.4.6. Требование субъекта персональных данных о прекращении обработки персональных данных
          – в течение 10 дней;
        </p>
        <p>
          3.4.7. Истечение сроков исковой давности для правоотношений, в рамках которых
          осуществляется либо осуществлялась обработка персональных данных; ликвидация
          (реорганизация) Организации.
        </p>
        <p>
          3.5. В случае установления факта неправомерной или случайной передачи (предоставления,
          распространения, доступа) персональных данных, повлекшей нарушение прав субъектов
          персональных данных, ООО НПЦ "ВИРТЭК" уведомляет Роскомнадзор:
        </p>
        <p>
          3.5.1. В течение 24 часов о произошедшем инциденте, о предполагаемых причинах, повлекших
          нарушение прав субъектов персональных данных, и предполагаемом вреде, нанесенном правам
          субъектов персональных данных, о принятых мерах по устранению последствий соответствующего
          инцидента, а также предоставляет сведения о лице, уполномоченном на взаимодействие с
          Роскомнадзором, по вопросам, связанным с выявленным инцидентом;
        </p>
        <p>
          3.5.2. В течение 72 часов о результатах внутреннего расследования выявленного инцидента, а
          также предоставляет сведения о лицах, действия которых стали причиной выявленного
          инцидента (при наличии).
        </p>
        <p>3.6. Трансграничная передача персональных данных не осуществляется.</p>
        <h2>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</h2>
        <p>
          4.1. Содержание и объем обрабатываемых персональных данных определяются исходя из целей
          обработки. Не обрабатываются персональные данные, избыточные или несовместимые по
          отношению к следующим основным целям:
        </p>
        <p>
          4.1.1. Идентификации Пользователя, зарегистрированного на Сайте, для оформления заявки на
          обратный звонок или связь по электронной почте.
        </p>
        <p>
          4.1.2. Установления с Пользователем обратной связи, включая направление уведомлений,
          запросов, касающихся использования Сайта, оказания услуг, обработка запросов и заявок,
          ознакомление, просмотр документов, иной информации от Пользователя, содержащих
          персональные данные Пользователя.
        </p>
        <p>
          4.1.3. Заключения гражданско-правовых договоров с Пользователем и используемых
          Организацией исключительно для исполнения гражданскоправовых договоров.
        </p>
        <h2>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</h2>
        <p>
          5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока,
          любым законным способом, в том числе в информационных системах персональных данных с
          использованием средств автоматизации или без использования таких средств.
        </p>
        <p>
          5.2. Персональные данные Пользователя могут быть переданы уполномоченным органам
          государственной власти Российской Федерации только по основаниям и в порядке,
          установленным законодательством Российской Федерации.
        </p>
        <p>
          5.3. При утрате или разглашении персональных данных Администрация сайта информирует
          Пользователя об утрате или разглашении персональных данных.
        </p>
        <p>
          5.4. Организация принимает необходимые организационные и технические меры для защиты
          персональной информации Пользователя от неправомерного или случайного доступа,
          уничтожения, изменения, блокирования, копирования, распространения, а также от иных
          неправомерных действий третьих лиц.
        </p>
        <p>
          5.5. Организация совместно с Пользователем принимает все необходимые меры по
          предотвращению убытков или иных отрицательных последствий, вызванных утратой или
          разглашением персональных данных Пользователя.
        </p>
        <h2>6. СБОР ПЕРСОНАЛЬНЫХ ДАННЫХ НЕСОВЕРШЕННОЛЕТНИХ</h2>
        <p>
          Наш сайт не предназначен для обработки персональных данных несовершеннолетних. Если у Вас
          есть основания полагать, что несовершеннолетний предоставил нам свои персональные данные
          через сайт, то просим Вас сообщить нам об этом, написав на почту info@virtek.pro
        </p>
        <h2>7. COOKIE- ФАЙЛЫ</h2>
        <p>7.1. Какие cookie-файлы собираются на сайте:</p>
        <p>
          7.1.1. Технические сookie-файлы — сбор данных файлов нельзя запретить при посещении, так
          как они необходимы для правильной работы и предоставления полного функционала Сайта.
        </p>
        <p>
          7.1.2. Аналитические cookie-файлы — собираются через средства вебаналитики с целью общего
          анализа использования Сайта и получения данных о действиях Пользователей на Сайте для
          улучшения его функционала.
        </p>
        <p>7.2. Цели сбора cookie-файлов:</p>
        <table class="iksweb">
          <tbody>
            <tr>
              <td>Наименование</td>
              <td>Провайдер</td>
              <td>Срок хранения</td>
              <td>Описание</td>
            </tr>
            <tr>
              <td>_i</td>
              <td>yandex.ru</td>
              <td>10 лет</td>
              <td>Позволяет различать посетителей</td>
            </tr>
            <tr>
              <td>_ym_isad</td>
              <td>.virtek.pro</td>
              <td>1 день</td>
              <td>Определяют наличие блокировки рекламы в вашем браузере</td>
            </tr>
            <tr>
              <td>_ym_visorc</td>
              <td>.virtek.pro</td>
              <td>1 день</td>
              <td>
                Сохраняют информацию о действиях, которые были выполнены при посещении веб-сайта,
                включая поиск по ключевым словам
              </td>
            </tr>
            <tr>
              <td>_ym_d</td>
              <td>.virtek.pro</td>
              <td>1 год</td>
              <td>Запоминают дату входа на сайт</td>
            </tr>
            <tr>
              <td>_ym_uid </td>
              <td>.virtek.pro</td>
              <td>1 год</td>
              <td>
                Собирают обезличенную информацию о поведении посетителя сайта и обезличенной
                статистики посетителей
              </td>
            </tr>
            <tr>
              <td>_gid </td>
              <td>.virtek.pro</td>
              <td>1 день</td>
              <td>Регистрирует данные о поведении посетителей на сайте</td>
            </tr>
            <tr>
              <td>_yabs-sid </td>
              <td>.yandex.ru</td>
              <td>Период сессии</td>
              <td>Идентификатор визита</td>
            </tr>
            <tr>
              <td>_gdpr </td>
              <td>yandex.ru</td>
              <td>2 года</td>
              <td>
                Позволяет различать посетителей из зоны действия Генерального регламента о защите
                данных (General Data Protection Regulation, GDPR).
              </td>
            </tr>
            <tr>
              <td>_ymex </td>
              <td>yandex.ru</td>
              <td>1 год</td>
              <td>
                Хранит вспомогательную информацию для работы Метрики: время создания идентификаторов
                и их альтернативные значения.
              </td>
            </tr>
          </tbody>
        </table>
        <p>7.3. Как можно запретить сбор cookie-файлов на сайте:</p>
        <p>
          7.3.1. Если Пользователь не хочет, чтобы cookie-файлы сохранялись на его устройстве, то
          отключить эту опцию можно в настройках браузера. Сохраненные cookie-файлы также можно
          удалить в любое время в системных настройках браузера. Пользователь может изменить
          настройки браузера, чтобы принимать или отклонять по умолчанию все cookie-файлы либо
          cookie-файлы с Сайта.
        </p>
        <p>
          7.4. Организация использует сервис Яндекс.Метрика который позволяет анализировать
          активность Пользователей Сайта и улучшать его работу.
        </p>
        <h2>8. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
        <p>
          8.1. Субъекты персональных данных, персональные данные которых обрабатываются оператором,
          имеют право на:
        </p>
        <p>8.1.1. Получение полной информации об обработке оператором его персональных данных;</p>
        <p>
          8.1.2. Уточнение своих персональных данных, их блокирование или уничтожение в случае, если
          персональные данные являются неполными, устаревшими, неточными, незаконно полученными или
          не являются необходимыми для заявленной цели обработки;
        </p>
        <p>8.1.3. Отзыв согласия на обработку персональных данных;</p>
        <p>
          8.1.4. Обжалование действия или бездействия оператора, осуществляемого с нарушением
          требований законодательства Российской Федерации в области персональных данных, в
          уполномоченный орган по защите прав субъектов персональных данных или в суд;
        </p>
        <p>
          8.1.5. Защиту своих прав и законных интересов, в том числе на возмещение убытков и / или
          компенсацию морального вреда в судебном порядке;
        </p>
        <p>
          8.1.6. Осуществление иных прав, предусмотренных законодательством Российской Федерации о
          персональных данных.
        </p>
        <p>8.2. Оператор вправе:</p>
        <p>
          8.2.1. Получать документы, содержащие персональные данные от субъектов персональных данных
          либо от представителей субъекта персональных данных;
        </p>
        <p>
          8.2.2. Требовать от субъекта персональных данных своевременного уточнения предоставленных
          персональных данных;
        </p>
        <p>
          8.2.3. В случае отзыва субъектом персональных данных согласия на обработку своих
          персональных данных, оператор вправе продолжить обработку персональных данных без согласия
          субъекта персональных данных при наличии оснований, установленных законодательством
          Российской Федерации.
        </p>
        <p>8.3. Пользователь обязан:</p>
        <p>
          8.3.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом
        </p>
        <p>
          8.3.2. Обновить, дополнить предоставленную информацию о персональных данных в случае
          изменения данной информации.
        </p>
        <p>8.4. Оператор обязан:</p>
        <p>
          8.4.5. Использовать полученную информацию исключительно для целей, указанных в п.4
          настоящей Политики конфиденциальности.
        </p>
        <p>
          8.4.6. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без
          предварительного письменного разрешения Пользователя, а также не осуществлять продажу,
          обмен, опубликование, либо разглашение иными возможными способами переданных персональных
          данных Пользователя, за исключением п.п.5.2. и 5.3. настоящей Политики Конфиденциальности.
        </p>
        <p>
          8.4.7. Принимать меры предосторожности для защиты конфиденциальности персональных данных
          Пользователя согласно порядку, обычно используемого для защиты такого рода информации в
          существующем деловом обороте.
        </p>
        <p>
          8.4.8. Осуществить блокирование персональных данных, относящихся к соответствующему
          Пользователю, с момента обращения или запроса Пользователя или его законного представителя
          либо уполномоченного органа по защите прав субъектов персональных данных на период
          проверки, в случае выявления недостоверных персональных данных или неправомерных действий.
        </p>
        <h2>9. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
        <p>
          9.1. Организация, не исполнившая свои обязательства, несёт ответственность за убытки,
          понесённые Пользователем в связи с неправомерным использованием персональных данных, в
          соответствии с законодательством Российской Федерации, за исключением случаев,
          предусмотренных п.п.5.2., 5.3. и 9.2. настоящей Политики Конфиденциальности.
        </p>
        <p>
          9.2. В случае утраты или разглашения Конфиденциальной информации Организация не несёт
          ответственность, если данная конфиденциальная информация:
        </p>
        <p>9.2.1. Стала публичным достоянием до её утраты или разглашения.</p>
        <p>9.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.</p>
        <p>9.2.3. Была разглашена с согласия Пользователя.</p>
        <h2>10. РАЗРЕШЕНИЕ СПОРОВ</h2>
        <p>
          10.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем
          сайта и Организацией, обязательным является предъявление претензии (письменного
          предложения о добровольном урегулировании спора на юридический адрес Организации).
        </p>
        <p>
          10.2. Получатель претензии в течение 20 календарных дней со дня получения претензии,
          письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
        </p>
        <p>
          10.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в
          соответствии с действующим законодательством Российской Федерации.
        </p>
        <p>
          10.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и
          Организацией применяется действующее законодательство Российской Федерации.
        </p>
        <h2>11. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h2>
        <p>
          11.1. Организация вправе вносить изменения в настоящую Политику конфиденциальности без
          согласия Пользователя.
        </p>
        <p>
          11.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте,
          если иное не предусмотрено новой редакцией Политики конфиденциальности.
        </p>
        <p>
          11.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует
          сообщать посредством электронной почты info@virtek.pro.
        </p>
        <p>
          11.4. Действующая Политика конфиденциальности Организации размещена на странице по адресу
          https://virtek.pro/politika
        </p>
      </div>
    </>
  );
};

export default Politika;
