import React from 'react';
import Header from '../components/Header';

const NotFound = () => {
  return (
    <>
      <Header />
      <div className="not-found">
        <p>Данной страницы не существует</p>
      </div>
    </>
  );
};

export default NotFound;
