import React from 'react';
import { Link } from 'react-router-dom';

import Form from '../components/Form';
import Footer from '../components/Footer';
import Company from '../components/Company';

import logo from '../assets/img/svg/newLogo.svg';
import tenYears from '../assets/img/about/tenYears.png';
import projectForCompany from '../assets/img/about/projectForCompany.png';
import completedProjects from '../assets/img/about/completedProjects.png';

import tekstroylabCompany from '../assets/img/company/tekstroylab.svg';
import iztechCompany from '../assets/img/company/iztech.svg';
import energyCompany from '../assets/img/company/energyCompany.svg';

const About = () => {
  const [modalActive, setModalActive] = React.useState(false);
  window.scroll(0, 0);
  return (
    <div className="about">
      <div className="about__header">
        <div className="about__header-container">
          <div className="about__header-nav">
            <Link to="/">Услуги</Link>
            <Link to="/about">О компании</Link>
          </div>
          <Link to="/">
            <img src={logo} alt="Логотип сайта Виртэк" />
          </Link>
          <a href="#form" className="about__header-button" onClick={() => setModalActive(true)}>
            Связаться с нами
          </a>
        </div>
      </div>
      <div className="about__main">
        <h2>Рутина - машинам, людям - идеи</h2>
        <p>
          Наша компания работает с использование российского ПО, такого как StaffCop , IT Invent,
          DIRECTUM, 1С:ERP, 1C: Бухгалтерия, 1C: ЗУП, включенных в{' '}
          <a href="https://reestr.digital.gov.ru/reestr/302046/">реестр отечественного ПО.</a>
        </p>
      </div>
      <div className="about__content">
        <div className="block">
          <a href="#">
            <img src={tenYears} alt="Картинка для услуги" />
            {/* <div className="block-text">
              <span>10 лет успешного опыта</span>
            </div> */}
          </a>
        </div>
        <div className="block">
          <a href="#">
            <img src={projectForCompany} alt="Картинка для услуги" />
            {/* <div className="block-text">
              <span>Проекты для крупных инжиниринговых компаний</span>
            </div> */}
          </a>
        </div>
        <div className="block">
          <a href="#">
            <img src={completedProjects} alt="Картинка для услуги" />
            {/* <div className="block-text">
              <span>Более 20 реализованных проектов</span>
            </div> */}
          </a>
        </div>
      </div>
      <div className="about__content about__block last-block">
        <span>
          Миссия нашей компании - обеспечить автоматизацию бизнеса и освободить больше ресурсов
          компании-заказчика, предоставить конкурентные преимущества бизнесу со стороны ИТ. Мы
          займемся всей операционкой, а вы сможете сфокусироваться на том, что действительно важно
          для роста и развития.
        </span>
        <span>
          Для каждой компании подбираем отдельную стратегию работы, удовлетворяющую индивидуальным
          запросам и потребностям:
        </span>
        <span>
          <p>
            <b>• Автоматизация бизнес-процессов</b>
          </p>
          <p>CRM-системы, IP-телефония и информационная безопасность.</p>
          <p>
            <b>• Решения для бизнеса</b>
          </p>
          <p>
            Ремонт компьютеров, поставка цифровой техники, импортозамещение, чат-боты и API-модули.
          </p>
          <p>
            <b>• Бизнес под ключ</b>
          </p>
          <p>
            Электронная коммерция, мобильные приложения, корпоративные порталы, интернет-магазины,
            отраслевая разработка и дизайн.
          </p>
          <p>
            <b>• Модернизация</b>
          </p>
          <p>
            Серверы и системы хранения данных, ЦОД, виртуализация рабочих мест, инженерные системы и
            облачный офис.
          </p>
          <p>
            <b>• IT-аутсорсинг</b>
          </p>
          <p>
            Поддержка серверов, 1С-сопровождение, техническая поддержка пользователей, монтаж ЛВС,
            видеонаблюдение и СКУД.
          </p>
        </span>
        <span>
          <b>Почему VIRTEK?</b>
        </span>
        <span>
          Мы занимаемся автоматизацией бизнеса 10 лет, и за это время реализовали больше 20
          проектов. Внедряем решения в крупные инженерные, производственные предприятия и небольшие
          частные компании. Размер бизнеса значения не имеет - мы тщательно и усердно работаем с
          каждым клиентом.
        </span>
        <span>
          <b>Среди наших заказчиков:</b>
        </span>
        {/* <span>
          ТЕХНОТЕКС , ТЕХНОМОДУЛЬК, ЭКМ ХОЛДИНГ, КРАСНЫЙ ПРОЛЕТАРИЙ, ТЕХСТРОЙЛАБ, ЭКОЙЛ, ИНТЕХ,
          СИНЭРГИЯ, ОКБТМ
        </span> */}
      </div>
      <div className="about__content company__blocks">
        <Company link={`https://technotecs.ru/`} img={`https://technotecs.ru/img/logo.png`} />
        <Company
          link={`https://technomodulk.ru/`}
          img={`http://technomodulk.ru/wp-content/uploads/2021/11/%D0%BB%D0%BE%D0%B3%D0%BE%D1%82%D0%BF-%D0%A2%D0%9C%D0%9A-%D0%BD%D0%B0-%D1%81%D0%B0%D0%B9%D1%82-e1638186609121.png`}
        />
        <Company
          link={`http://ntp-ecoil.ru/`}
          img={`http://ntp-ecoil.ru/thumb/2/AirvMeWaA-9M0ek1A4Sp7A/286r286/d/top_logo.png`}
        />
        <Company
          link={`https://krpr.ru/`}
          img={`http://krpr.ru/wp-content/uploads/2019/07/logo_krpr60.png`}
        />
      </div>
      <div className="about__content company__blocks">
        <Company
          link={`https://kvadrit.ru/`}
          img={`https://static.tildacdn.com/tild3234-6564-4564-a363-353362663436/photo.svg`}
        />
        <Company
          link={`https://ek-m.com/`}
          img={`https://ek-m.com/images/sitefiles/12/logo1.svg`}
        />
        <Company link={`https://izteh.info/`} img={iztechCompany} />
        <Company link={`https://tekhstroylab.ru/`} img={tekstroylabCompany} />
      </div>
      <div className="about__content company__blocks">
        <Company
          link={`http://www.okbtm.ru/`}
          img={`http://www.okbtm.ru/images/logo/logo_145x48.png`}
        />
        <Company link={`https://ecabel.com/calc`} img={`https://ecabel.com/images/logo-dark.svg`} />
        <Company link={`https://enegyatom.ru/`} img={energyCompany} />
        <Company link={`https://ikstr.ru/index.html`} img={`https://ikstr.ru/img/svg/logo.svg`} />
      </div>
      {/* <div class="partners__swiper-container">
        <button
          class="partners__swiper-btn partners__swiper-btn--prev btn"
          aria-label="кнопка предыдущий слайд">
          <svg
            class="partners__btn-prev-svg partners__swiper-btn-svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              class="partners__btn-svg-bg"
              d="M15 30C6.71573 30 8.89561e-07 23.2843 1.25168e-06 15C1.61379e-06 6.71573 6.71573 -1.01779e-06 15 -6.55671e-07C23.2843 -2.93554e-07 30 6.71573 30 15C30 23.2843 23.2843 30 15 30Z"
              fill="#ECECEC"
            />
            <path
              class="partners__btn-svg-border"
              d="M15 30C6.71573 30 8.89561e-07 23.2843 1.25168e-06 15C1.61379e-06 6.71573 6.71573 -1.01779e-06 15 -6.55671e-07C23.2843 -2.93554e-07 30 6.71573 30 15C30 23.2843 23.2843 30 15 30Z"
              stroke="transparent"
            />
            <path class="partners__btn-svg-arrow" d="M16 20L11 15L16 10" stroke="black" />
          </svg>
        </button>

        <button
          class="partners__swiper-btn partners__swiper-btn--next btn"
          aria-label="кнопка следующий слайд">
          <svg
            class="partners__btn-next-svg partners__swiper-btn-svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 -1.01779e-06 15 -6.55671e-07C6.71573 -2.93554e-07 2.93554e-07 6.71573 6.55671e-07 15C1.01779e-06 23.2843 6.71573 30 15 30Z"
              fill="#ECECEC"
            />
            <path
              d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 -1.01779e-06 15 -6.55671e-07C6.71573 -2.93554e-07 2.93554e-07 6.71573 6.55671e-07 15C1.01779e-06 23.2843 6.71573 30 15 30Z"
              stroke="transparent"
            />
            <path d="M14 20L19 15L14 10" stroke="black" />
          </svg>
        </button>

        <div class="partners__container flex">
          <div class="partners__swiper-wrapper swiper-wrapper">
            <Company link={`https://technotecs.ru/`} img={`https://technotecs.ru/img/logo.png`} />
            <Company
              link={`https://technomodulk.ru/`}
              img={`http://technomodulk.ru/wp-content/uploads/2021/11/%D0%BB%D0%BE%D0%B3%D0%BE%D1%82%D0%BF-%D0%A2%D0%9C%D0%9A-%D0%BD%D0%B0-%D1%81%D0%B0%D0%B9%D1%82-e1638186609121.png`}
            />
            <Company
              link={`http://ntp-ecoil.ru/`}
              img={`http://ntp-ecoil.ru/thumb/2/AirvMeWaA-9M0ek1A4Sp7A/286r286/d/top_logo.png`}
            />
            <Company
              link={`https://krpr.ru/`}
              img={`http://krpr.ru/wp-content/uploads/2019/07/logo_krpr60.png`}
            />
            <Company
              link={`https://kvadrit.ru/`}
              img={`https://static.tildacdn.com/tild3234-6564-4564-a363-353362663436/photo.svg`}
            />
            <Company
              link={`https://ek-m.com/`}
              img={`https://ek-m.com/images/sitefiles/12/logo1.svg`}
            />
            <Company link={`https://izteh.info/`} img={iztechCompany} />
            <Company link={`https://tekhstroylab.ru/`} img={tekstroylabCompany} />
            <Company
              link={`http://okbtm.ru/`}
              img={`http://okbtm.ru/images/logo/logo_145x48.png`}
            />
            <Company
              link={`https://ecabel.com/calc`}
              img={`https://ecabel.com/images/logo-dark.svg`}
            />
            <Company link={`https://enegyatom.ru/`} img={energyCompany} />
            <Company
              link={`https://ikstr.ru/index.html`}
              img={`https://ikstr.ru/img/svg/logo.svg`}
            />
          </div>
        </div>
      </div> */}
      <Form />
      <Footer />
      <Form active={modalActive} setActive={setModalActive} />
    </div>
  );
};

export default About;
